import React, {useState, useContext} from 'react'
import {ToastNotification} from 'carbon-components-react'
import {validateUser, useUserValidationState} from '../../util'
import { SubmitButton, TextField } from '../../components/carbon-entitysync';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaSubmit from '../../util/recaptchaSubmit';
import {ProviderContext} from './../../components/provider-wrapper';

export default function Expired({data, pageContext, location}) {
    const [hasErrors, setHasErrors] = useState(false);
    const [success, setSuccess] = useState(false)
    const validationState = useUserValidationState();
    const validationStateVals = {};
    const validationStateSetters = {};
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [isLoading, setIsLoading] = useState(false);
    if(!location){
      return (
        <></>
      )
    }

    for(let key of Object.keys(validationState)){
      validationStateVals[key] = validationState[key][0];
      validationStateSetters[key] = validationState[key][1];
    }

    function SubmitUserButton(){
        const onSubmit = async (e, unsavedChanges, entityData) => {
          e.persist();
          setIsLoading(true);
          try {
            const valid = await recaptchaSubmit(executeRecaptcha)
            if (!valid) throw "Failed recaptcha"
            if(!validateUser(entityData, validationState)){
              e.preventDefault();
              setHasErrors(true);
              setSuccess(false);
            }else{
              setHasErrors(false);
              setSuccess(true);
            }
            setIsLoading(false);
          } catch (err) {
            console.log(err)
            setHasErrors(true);
            setSuccess(false);
            setIsLoading(false);
          }
        };
        return (
          <SubmitButton onSubmit={onSubmit}  disabled={isLoading}>
            Submit
          </SubmitButton>
        );
    }


    return (
        <div>
          <>
              {hasErrors && <div className="toast-container">
                  <ToastNotification
                      caption=""
                      iconDescription="Close"
                      subtitle={<span>Please check the highlighted fields and submit the form again</span>}
                      timeout={0}
                      title="Some fields are invalid"
                      kind="error"
                      lowContrast={true}
                      onCloseButtonClick={(e) => setHasErrors(false)}
                  />
                  </div>}
                  {success && <div className="toast-container">
                  <ToastNotification
                      caption=""
                      iconDescription="Close"
                      subtitle=""
                      timeout={0}
                      title="Your information was updated"
                      kind="success"
                      lowContrast={true}
                  />
                  </div>}
                  <div className="submit-form submit-user-form">
                  <TextField light
                      labelText="Primary Contact"
                      invalid={validationStateVals.field_contact_name}
                      invalidText={validationStateVals.field_contact_name ? validationStateVals.field_contact_name : ""}
                      onChange={() => validationStateSetters.field_contact_name(false)}
                      field="field_contact_name"/>

                  <TextField light
                      labelText="Email"
                      disabled
                      field="mail"/>

                  <TextField light
                      labelText="Organization Name"
                      invalid={validationStateVals.field_organization_name}
                      invalidText={validationStateVals.field_organization_name ? validationStateVals.field_organization_name : ""}
                      onChange={() => validationStateSetters.field_organization_name(false)}
                      field="field_organization_name"/>
                  <TextField light
                      labelText="Primary Phone"
                      invalid={validationStateVals.field_phone}
                      invalidText={validationStateVals.field_phone ? validationStateVals.field_phone : ""}
                      onChange={() => validationStateSetters.field_phone(false)}
                      placeholder="1 555-555-5555"
                      field="field_phone"/>
                  <TextField light
                      labelText="Website"
                      isUrl={true}
                      invalid={validationStateVals.field_website}
                      invalidText={validationStateVals.field_website ? validationStateVals.field_website : ""}
                      onChange={() => validationStateSetters.field_website(false)}
                      placeholder="http://"
                      field="field_website"/>
                  <TextField light
                      labelText="DBA (optional)"
                      
                      field="field_dba"/>  
                  <TextField light
                      labelText="Provider Enrollment ID or eXPRS ID"
                      invalid={validationStateVals.field_provider_or_express_id}
                      invalidText={validationStateVals.field_provider_or_express_id ? validationStateVals.field_provider_or_express_id : ""}
                      onChange={() => validationStateSetters.field_provider_or_express_id(false)}
                      field="field_provider_or_express_id"/> 
                  </div>
                  <SubmitUserButton />
          </>
        </div>
    )
}
